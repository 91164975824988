import React from 'react'

import Book from '../components/Book'
import Layout from '../components/Layout.js'

export default () => (
  <Layout>
    {/* styleClass="mb4r" , finish={'30/Nov/19'}*/}
    <div className="post__container">
      <h1 className="book-heading book-current">Currently Reading</h1>
      <Book
        bookName="Mythos: The Greek Myths Retold"
        authorName="Stephen Fry"
        start="06/Jul/21"
      />
      <Book
        bookName="Welcome to The Universe"
        authorName="Neil deGrasse Tyson et al."
        start="--/--/21"
      />
      <Book
        bookName="Welcome to The Universe: The Problem Book"
        authorName="Neil deGrasse Tyson et al."
        start="--/--/21"
      />
      <Book
        bookName="The Penguin History of the World"
        authorName="J.M. Roberts"
        start="--/--/21"
        styleClass="mb4r"
      />

      {/* HISTORY */}
      <h1 className="book-heading book-current">To read</h1>
      <h1 className="book-heading">History</h1>
      <Book
        bookName="Night"
        authorName="Elie Wiesel"
        start="02/Nov/20"
        finish="03/Nov/20"
        done
      />
      <Book
        bookName="Glimpses of World History"
        authorName="Jawaharlal Nehru"
      />
      <Book bookName="Discovery of India" authorName="Jawaharlal Nehru" />
      <Book bookName="Wonder That Was India" authorName="A.L Basham" />
      <Book bookName="India Unbound" authorName="Gurcharan Das" />
      <Book bookName="Guns, Germs and Steel" authorName="Jared Diamond" />
      <Book bookName="Distant Mirror" authorName="Barbara W. Tuchman" />
      <Book bookName="Guns of August" authorName="Barbara W. Tuchman" />
      <Book bookName="Connections" authorName="James Burke" />
      <Book
        bookName="Sapiens: A Brief History of Humankind"
        authorName="Yuval Noah Harari"
        reread={true}
      />
      <Book
        bookName="The Rise and Fall of the Great Powers"
        authorName="Paul Kennedy"
      />
      <Book
        bookName="Mastering Modern World History"
        authorName="Norman Lowe"
      />
      <Book
        bookName="The Creators: A History of Heroes of the Imagination"
        authorName="Daniel J. Boorstin"
      />
      <Book
        bookName="Ideas: A History of Thought and Invention, from Fire to Freud"
        authorName="Peter Watson"
      />
      <Book
        bookName="How We Got to Now: Six Innovations That Made the Modern World"
        authorName="Steven Johnson"
      />

      {/* International affairs */}
      <h1 className="book-heading book-topic">
        International affairs & Geopolitics
      </h1>
      <Book
        bookName="The Looming Tower: Al Qaeda and the Road to 9/11"
        authorName="Lawrence Wright"
        start="29/Apr/21"
        finish="07/May/21"
        done
      />
      <Book
        bookName="Ghost Wars: The Secret History of the CIA, Afghanistan, and Bin Laden from the Soviet Invasion to September 10, 2001"
        authorName="Steve Coll"
        start="08/May/2021"
        finish="PAUSED@41%"
      />
      <Book
        bookName="World Order"
        authorName="Henry Kissinger"
        start="22/Nov/20"
        finish="PAUSED"
      />
      <Book bookName="Diplomacy" authorName="Henry Kissinger" />

      {/* PSYCHOLOGY */}
      <h1 className="book-heading book-topic">Psychology</h1>
      <Book
        bookName="The Social Animal"
        authorName="Elliot Aronson"
        start="04/Aug/20"
        finish="19/Oct/20"
        done
      />
      <Book
        bookName="Algorithms To Live By: The Computer Science of Human Decisions"
        authorName="Brian Christian & Tom Griffiths"
        start="26/Jul/20"
        finish="04/Aug/20"
        done
      />

      <Book bookName="Thinking Fast and Slow" authorName="Daniel Kahneman" />
      <Book bookName="Predictably Irrational" authorName="Dan Ariely" />
      <Book bookName="The Moral Animal" authorName="Robert Wright" />
      <Book bookName="Book of Bad Arguments" authorName="Ali Almossawi" />
      <Book
        bookName="Behave: The Biology of Humans at Our Best and Worst"
        authorName="Robert M.
        Sapolsky"
      />

      {/* PHILOSOPHY */}
      <h1 className="book-heading book-topic">Philosophy</h1>
      <Book
        bookName="Justice"
        authorName="Michael J. Sandel"
        done
        finish="30/Nov/19"
      />
      <Book bookName="Story of Philosophy" authorName="Will Durant" />
      <Book
        bookName="Man's Search for Meaning"
        authorName="Viktor Frankl"
        reread
      />
      <Book bookName="Sophie's World" authorName="Jostein Gaarder" />
      <Book bookName="The Prince" authorName="Niccolò Machiavelli" />
      <Book bookName="Fragments" authorName="Heraclitus" />
      <Book bookName="Tao Te Ching" authorName="Lao Tzu" />
      <Book bookName="The Invention of Religion" authorName="Alexander Drake" />
      <Book
        bookName="The Moral Sayings of Publius Syrus: A Roman Slave"
        authorName=" Publilius Syrus"
      />
      <Book
        bookName="What Does It All Mean? A Very Short Introduction to Philosophy"
        authorName="Thomas Nagel"
      />

      {/* STOICISM */}
      <h1 className="book-heading book-topic">Stoicism</h1>
      <Book
        bookName="On the Shortness of Life"
        authorName="Seneca"
        start="22/Dec/20"
        finish="PAUSED"
      />
      <Book
        bookName="Meditations"
        authorName="Marcus Aurelius"
        start="22/Jul/20"
        finish="PAUSED"
      />
      <Book
        bookName="Discourses and Selected Writings"
        authorName="Epictetus"
      />
      <Book bookName="Letters from a Stoic" authorName="Seneca" />

      {/* PHYSICS */}
      <h1 className="book-heading book-topic">Physics</h1>
      <Book
        bookName="Six Easy Pieces: Essentials of Physics By Its Most Brilliant Teacher"
        authorName="Richard Feynman"
        start="14/Mar/20"
        finish="PAUSED"
      />
      <Book
        bookName="Relativity: The Special and the General Theory"
        authorName="Albert Einstein"
      />
      <Book
        bookName="Brief History of Time"
        authorName="Stephen Hawking"
        reread={true}
      />
      <Book
        bookName="Brief Answers to the Big Questions"
        authorName="Stephen Hawking"
      />
      <Book bookName="Storm in a Teacup" authorName="Helen Czerski" />
      <Book
        bookName="Physics for Future Presidents"
        authorName="Richard A. Muller"
      />
      <Book bookName="The Order of Time" authorName="Carlo Rovelli" />
      <Book
        bookName="Reality is Not What it Seems: The Journey to Quantum Gravity"
        authorName="Carlo Rovelli"
      />
      <Book
        bookName="What is Real? The Unfinished Quest for the Meaning of Quantum Physics"
        authorName="Adam Becker"
      />
      <Book
        bookName="Six Not-So-Easy Pieces: Einstein's Relativity, Symmetry, and
          Space-Time"
        authorName="Richard Feynman"
      />
      <Book
        bookName="QED: The Strange Theory of Light and Matter"
        authorName="Richard Feynman"
      />
      {/* SCIENCE */}
      <h1 className="book-heading book-topic">Science</h1>
      <Book
        bookName="The Body: A Guide for Occupants"
        authorName="Bill Bryson"
        start="24/Dec/19"
        finish="PAUSED"
      />
      <Book bookName="The Violinist's Thumb" authorName="Sam Kean" />
      <Book bookName="Caesar's Last Breath" authorName="Sam Kean" />
      <Book bookName="The Disappearing Spoon" authorName="Sam Kean" />
      <Book bookName="Stuff Matters" authorName=" Mark Miodownik" />
      <Book bookName="Napoleans's Button" authorName="Penny Le Couteur" />
      <Book bookName="The Selfish Gene" authorName="Richard Dawkins" />
      <Book bookName="King Solomon's Ring" authorName="Konrad Lorenz" />
      <Book bookName="Wonders of Life" authorName="Brian Cox" />
      <Book
        bookName="Structures: Or Why Things Don't Fall Down"
        authorName="J. E. Gordon"
      />
      <Book
        bookName="The Story of Earth: The First 4.5 Billion Years, from Stardust to
          Living Planet"
        authorName="Robert M. Hazen"
      />
      {/* <p class="book-text">
        <b>Built: The Hidden Stories Behind our Structures</b> - Roma Agrawal
      </p> */}

      {/* Math and statistics */}
      <h1 className="book-heading book-topic">Math & Statistics</h1>
      <Book bookName="Prelude to Mathematics" authorName="W. W. Sawyer " />
      <Book
        bookName="The Joy of x: A Guided Tour of Math, from One to Infinity"
        authorName="Steven H. Strogatz"
      />
      <Book
        bookName="How Not to Be Wrong: The Power of Mathematical Thinking"
        authorName=" Jordan Ellenberg"
      />
      <Book bookName="How to Lie with Statistics" authorName=" Darrell Huff" />
      <Book
        bookName="The Signal and the Noise: Why So Many Predictions Fail - But Some Don't"
        authorName="Nate Silver"
      />
      <Book
        bookName="The Code Book: The Science of Secrecy from Ancient Egypt to Quantum Cryptography"
        authorName="Simon Singh"
      />

      {/* NEUROSCIENCE */}
      <h1 className="book-heading book-topic">Neuroscience</h1>
      <Book
        bookName="Brain: Very Short Introduction"
        authorName="Michael O'Shea"
        start="02/Dec/19"
        finish="PAUSED"
      />
      <Book
        bookName="The Brain: The Story of You"
        authorName="David Eagleman"
      />
      <Book bookName="Phantoms in The Brain" authorName="V.S. Ramachandran" />
      <Book
        bookName="The Tale of Duelling Neurosurgeons"
        authorName="Sam Kean"
      />
      {/* ECONOMICS */}
      <h1 className="book-heading book-topic">Economics</h1>
      <Book
        bookName="Economics: The User's Guide"
        authorName="Ha-Joon Chang"
        done
        start="03/Nov/20"
        finish="21/Nov/20"
      />
      <Book
        bookName="IIMA-Day to Day Economics"
        authorName="Satish Y. Deodhar"
        reread
        done
        start="06/Feb/20"
        finish="22/Feb/20"
      />
      <Book bookName="Basic Economics" authorName="Thomas Sowell" />
      <Book
        bookName="Economic Facts and Fallacies"
        authorName="Thomas Sowell"
      />
      <Book bookName="Economics in One Lesson" authorName="Henry Hazlitt" />
      <Book bookName="Naked Money" authorName="Charles Wheelan" />

      {/* COMPUTER SCIENCE */}
      <h1 className="book-heading book-topic">Computer Science</h1>
      <Book
        bookName="Web Scalability for Startup Engineers"
        authorName="Artur Ejsmont"
      />
      <Book
        bookName="Node.js Design Patterns"
        authorName="Luciano Mammino et al."
      />
      <Book bookName="How Software Works" authorName="V. Anton Spraul" />
      <Book bookName="Don't make me think" authorName="Steve Krug" />
      <Book bookName="Functional-Light JavaScript" authorName="Kyle Simpson" />
      <Book bookName="Refactoring UI" authorName="Steve Schoger" />
      <Book
        bookName="Clean Code: A Handbook of Agile Software Craftsmanship"
        authorName=" Robert C. Martin"
      />
      <Book
        bookName="Code: The Hidden Language of Computer Hardware and Software"
        authorName="Charles Petzold"
      />

      {/* LITERARY CLASSICS */}
      <h1 className="book-heading book-topic">Literary Classics</h1>
      <Book
        bookName="A Christmas Carol"
        authorName="Charles Dickens"
        done
        start={'24/May/20'}
        finish={'24/May/20'}
      />

      <Book
        bookName="Animal Farm"
        authorName="George Orwell"
        reread
        done
        start={'23/May/20'}
        finish={'23/May/20'}
      />

      <Book
        bookName="Flowers for Algernon"
        authorName="Daniel Keyes"
        done
        start="04/Dec/19"
        finish="07/Dec/19"
      />

      <Book
        bookName="The Count of Monte Cristo"
        authorName="Alexandre Dumas"
        done
        start="20/Nov/19"
        finish="25/Nov/19"
      />

      <Book bookName="Mahabharata" />
      <Book bookName="War and Peace" authorName="Leo Tolstoy" />
      <Book bookName="Anna Karenina" authorName="Leo Tolstoy" />
      <Book bookName="Crime and Punishment" authorName="Fyodor Dostoyevsky" />
      <Book
        bookName="The Adventures of Huckleberry Finn"
        authorName="Mark Twain"
      />
      <Book bookName="Pride and Prejudice" authorName="Jane Austen" />
      <Book bookName="The Picture of Dorian Gray" authorName="Oscar Wilde" />

      <Book
        bookName="Alice's Adventures in Wonderland"
        authorName="Lewis Carroll"
      />
      <Book bookName="Frankenstein" authorName="Mary Wollstonecraft Shelley" />
      <Book bookName="The Stranger" authorName="Albert Camus" />
      <Book bookName="Farenheit 451" authorName="Ray Bradbury" />
      <Book bookName="Slaughterhouse-Five" authorName="Kurt Vonnegut Jr." />
      <Book bookName="Brave New World" authorName="Aldous Huxley" />
      <Book bookName="Charlotte's Web" authorName="E. B. White" />
      <Book bookName="Watership Down" authorName="Richard Adams" />
      <Book bookName="Forever War" authorName="Joe Haldeman" />
      <Book bookName="Dune" authorName="Frank Herbert" />
      <Book bookName="Ender's Game" authorName="Orson Scott Card" />
      <Book bookName="The Great Gatsby" authorName="F. Scott Fitzgerald" />
      <Book bookName="Of Mice and Men" authorName="John Steinbeck" />
      <Book bookName="Lord of the Flies" authorName="William Golding" />
      <Book bookName="Things Fall Apart" authorName="Chinua Achebe" />
      <Book bookName="A Room of One's Own" authorName="Virginia Woolf" />
      <Book bookName="The Pearl" authorName="John Steinbeck" />
      <Book bookName="The Tell-Tale Heart" authorName="Edgar Allan Poe" />
      <Book
        bookName="The Strange Case of Dr. Jekyll and Mr. Hyde"
        authorName="Robert Louis Stevenson"
      />
      <Book bookName="I, Claudius" authorName="Robert Graves" />
      <Book
        bookName="All Quiet on the Western Front"
        authorName="Erich Maria Remarque"
      />
      <Book bookName="The Handmaid's Tale" authorName="Margaret Atwood" />

      {/* <Book bookName="" authorName="" /> */}
      {/* POETRIES */}
      <h1 className="book-heading book-topic">Poetries</h1>
      <Book
        bookName="100 Best-Loved Poems (Dover Thrift Editions)"
        start="06/Feb/20"
        finish="PAUSED"
      />
      <Book bookName="The Golden Treasury: Of English Verse (Macmillan Collector's Library)" />
      <Book bookName="English Romantic Poetry: An Anthology (Dover Thrift Editions)" />
      <Book bookName="Shakespeare's Sonnets" authorName="William Shakespeare" />
      <Book bookName="The Odyssey" authorName="Homer" />
      <Book bookName="The Essential Rumi" authorName="Rumi" />
      <Book
        bookName="Lyrical Ballads 1798"
        authorName="William Wordsworth, Samuel Taylor Coleridge"
      />
      <Book bookName="Collected Poems" authorName="W.B. Yeats" />
      <Book
        bookName="Songs of Innocence and Songs of Experience"
        authorName="William Blake"
      />
      <Book
        bookName="The Marriage of Heaven and Hell"
        authorName="William Blake"
      />
      <Book
        bookName="Twenty Love Poems and a Song of Despair"
        authorName="Pablo Neruda"
      />

      <Book bookName="The Poems of Wilfred Owen" authorName="Wilfred Owen" />
      <Book bookName="The Waste Land and Other Poems" authorName="T.S. Eliot" />
      <Book bookName="Selected Poems" authorName="Emily Dickinson" />
      <Book bookName="The Raven and Other Poems" authorName="Edgar Allan Poe" />
      <Book
        bookName="The Poetry of Robert Frost: The Collected Poems"
        authorName="Robert Frost"
      />
      <Book bookName="Where the Sidewalk Ends" authorName="Shel Silverstein" />
      <Book bookName="Poems to Live Your Life By" authorName="Chris Riddell" />

      {/* MYTHOLOGY */}
      <h1 className="book-heading book-topic">Mythology</h1>
      <Book
        bookName="Norse Mythology"
        authorName="Neil Gaiman"
        start="18/Dec/19"
        finish="PAUSED"
      />
      <Book
        bookName="Heroes: Mortals and Monsters, Quests and Adventures"
        authorName="Stephen Fry"
      />
      <Book bookName="Troy" authorName="Stephen Fry" />
      <Book bookName="Indian Mythology" authorName="Devdutt Pattanaik" />
      <Book bookName="Bulfinch's Mythology" authorName="Thomas Bulfinch" />
      <Book
        bookName="Myths and Legends: An Illustrated Guide to Their Origins and Meanings"
        authorName="Philip Wilkinson"
      />

      {/* FICTION */}
      <h1 className="book-heading book-topic">Fiction</h1>
      <Book
        bookName="Project Hail Mary"
        authorName="Andy Weir"
        start="16/Jun/21"
        finish="01/Jul/21"
        done
      />
      <Book
        bookName="A Thousand Splendid Suns"
        authorName="Khaled Hosseini"
        start="28/Apr/21"
        finish="28/Apr/21"
        done
      />
      <Book
        bookName="The Paper Menagerie"
        authorName="Ken Liu"
        start="01/Nov/20"
        finish="20/Nov/20"
        done
      />

      {/* <Book bookName="World's Greatest Short Stories" /> */}
      <Book bookName="Kafka on the Shore" authorName="Haruki Murakami" />
      <Book bookName="Norwegian Wood" authorName="Haruki Murakami" />
      <Book bookName="Neverwhere" authorName="Neil Gaiman" />
      <Book bookName="The Graveyard Book" authorName="Neil Gaiman" />
      <Book bookName="Coraline" authorName="Neil Gaiman" />
      <Book bookName="Stardust" authorName="Neil Gaiman" />
      <Book bookName="American Gods" authorName="Neil Gaiman" />
      <Book
        bookName="The Ocean at the End of the Lane"
        authorName="Neil Gaiman"
      />
      <Book bookName="Number the Stars" authorName="Lois Lowry" />
      <Book bookName="Sea Prayer" authorName="Khaled Hosseini" />
      <Book bookName="The Martian" authorName="Andy Weir" />
      <Book bookName="All the Light We Cannot See" authorName="Anthony Doerr" />
      <Book
        bookName="The Time Traveler's Wife"
        authorName="Audrey Niffenegger"
      />
      <Book
        bookName="The Perks of Being a Wallflower"
        authorName="Stephen Chbosky"
      />

      {/* WRITING */}
      <h1 className="book-heading book-topic">Writing</h1>
      <Book
        bookName="Show, Don't Tell"
        authorName="Sandra Gerth"
        done
        start="05/Feb/20"
        finish="06/Feb/20"
      />

      <Book
        bookName="It was the Best of Sentences..."
        authorName="June Casagrande"
        done
        start="03/Feb/20"
        finish="04/Feb/20"
      />

      <Book
        bookName="Writing Tools"
        authorName="Roy Peter Clark"
        done
        start="28/Jan/20"
        finish="02/Feb/20"
      />

      <Book
        bookName="The Glamour of Grammar"
        authorName="Roy Peter Clark"
        done
        start="31/Dec/19"
        finish="27/Jan/20"
      />

      <Book
        bookName="Elements of Style"
        authorName="William Strunk Jr"
        done
        start="21/Dec/19"
        finish="28/Dec/19"
      />
      <Book bookName="On Writing" authorName="Stephen King" />
      <Book bookName="The Sense of Style" authorName="Steven Pinker" />
      <Book bookName="On Writing Well" authorName="William Zinsser" />
      <Book bookName="Zen in the Art of Writing" authorName="Ray Bradbury" />
      <Book bookName="Sin and Syntax" authorName="Constance Hale" />
      <Book bookName="The Anatomy of Story" authorName="John Truby" />
      <Book bookName="Dreyer's English" authorName="Benjamin Dreyer" />
      <Book bookName="Stein On Writing" authorName="Sol Stein" />
      <Book bookName="Art Matters" authorName="Neil Gaiman" />
      <Book bookName="The Shape of Ideas" authorName="Grant Snider" />

      {/* GRAPHIC NOVELS */}
      <h1 className="book-heading book-topic">Graphic Novels</h1>
      <Book bookName="Watchmen" authorName="Alan Moore" />
      <Book bookName="V for Vendetta" authorName="Alan Moore" />
      <Book bookName="Batman: Year One" authorName="Frank Miller" />
      <Book bookName="Maus" authorName="Art Spiegelman" />
      <Book bookName="Persepolis" authorName="Marjane Satrapi" />
      <Book bookName="Lost in Translation" authorName="Ella Frances Sanders" />
      <Book
        bookName="The Illustrated Book of Sayings"
        authorName="Ella Frances Sanders"
      />

      {/* OTHER NON-FICTIONS */}
      <h1 className="book-heading book-topic">Other Non-fictions</h1>
      <Book
        bookName="Endurance: Shackleton's Incredible Voyage"
        authorName="Alfred Lansing"
        start="11/Jun/21"
        finish="15/Jun/21"
        done
      />
      <Book
        bookName="A Feast of Vultures"
        authorName="Josy Joseph"
        start="15/Nov/19"
        finish="19/Nov/19"
        done
      />
      <Book
        bookName="The Personal MBA: Master the Art of Business"
        authorName="Josh Kaufman"
      />
      <Book bookName="The Periodic Table" authorName="Primo Levi" />
      <Book bookName="War of Art" authorName="Steven Pressfield" />
      <Book bookName="The 48 Laws of Power" authorName="Robert Greene" />
      <Book bookName="Psycho-Cybernetics" authorName="Maxwell Maltz" />
      <Book bookName="Barking Up The Wrong Tree" authorName="Eric Barker" />
      <Book
        bookName="Salt Sugar Fat: How the Food Giants Hooked Us"
        authorName="Michael Moss"
      />
      <Book
        bookName="Learn to Earn: A Beginner's Guide to the Basics of Investing and
          Business"
        authorName="Peter Lynch"
      />
      <Book
        bookName="Our Constitution: An introduction to India's Constitution and
          Constituitional Law"
        authorName="Subhash C. Kashyap"
      />
      {/* <Book bookName="" authorName="" />

      <p class="book-text">
        <b>
          The Subtle Art of Not Giving a F*ck: A Counterintuitive Approach to
          Living a Good Life
        </b>{' '}
        - Mark Manson */}
    </div>
  </Layout>
)
