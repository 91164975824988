import React from 'react'

const Book = ({
  styleClass = '',
  bookName,
  authorName = '',
  start = '',
  finish = '',
  done = false,
  reread = false
}) => (
  <p className={`book-text ${styleClass}`}>
    {done ? (
      <>
        {' '}
        <s>
          <span className="book-name">
            {bookName} {reread ? '(ReR)' : ''}
          </span>
        </s>{' '}
        - {authorName}
        {''} ({start} - {finish})
      </>
    ) : (
      <>
        <span className="book-name">
          {bookName} {reread ? '(ReR)' : ''}
        </span>
        - {authorName} {start ? `(${start} - ${finish})` : ''}
      </>
    )}
  </p>
)

export default Book
